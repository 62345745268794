@import '../../../assets/scss/variables';

.home {
    margin-top: 20px;

    .file-upload {
        margin-top: 20px;

        .p-button {
            .p-button-label {
                font-size: 15px;
                font-weight: 500;
            }

            .p-button-icon {
                font-size: 14px;
            }

            &.p-button-icon-only {
                width: 30px;
                height: 30px;
                padding: 0px;
                border-radius: 20px;
                background: #dbedf3;

                .p-button-icon {
                    color: $theme-gray;
                    font-size: 14px;
                }

                &:hover {
                    background: $secondary;

                    .p-button-icon {
                        color: $white;
                    }
                }
            }
        }
        .benefit-box{
            background: #f7f7f7;
            padding: 20px 15px;
            border-radius: 10px;
            border: 1px solid #eee;
            margin-bottom: 20px;
        }
    }
    .image-preview{
        margin-top: 5px;
        img {
            max-width: 100%;
            max-height: 50px;
            border-radius: 5px;
            background: #dfbbc2;
            padding: 4px;
        }
    }
}

.label-input-wrapper {
    display: flex;
    align-items: flex-start;
    .panel-card{
        background: #f7f7f7;
        padding: 20px 15px;
        border-radius: 10px;
    }
}

.label-wrapper {
    display: flex;
    align-items: left;

}

.input-wrapper {
    width: 100%;
    // padding: 10px;
    padding-top: 10px;
    box-sizing: border-box;
}

.panels-container {
    display: flex;
    justify-content: space-between;
    /* Adjust as needed */
}

.panel {
    flex: 1;
    /* Equal width for all panels */
    margin-right: 10px;
    /* Adjust margin as needed */
}

.invisible-label {
    opacity: 0;
}

.file-upload-section {
    padding: 0px 0px 0px 0px;
}