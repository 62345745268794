@import '../../assets/scss/variables';

.vouchers-table{
    margin-top: 1.5rem;
    .no-expander .p-row-toggler {
        display: none;
      }
      .disabled-row > * {
        background-color: #f0f0f0;
        color: #a0a0a0;
        .batch-column {
            .pi{
                color: #a0a0a0;
                &.pi-file{
                    pointer-events: none;
                }
            }
        }
      }
    .p-datatable-row-expansion{
        padding: 1rem 0rem;
        td{
            padding: 0rem 0rem !important;
        }
    }
    td{
        padding: 0rem !important;
        font-size: 14px;
        .p-row-toggler{
            margin-left: 10px;
            width: 22px !important;
            height: 22px !important;
            .p-row-toggler-icon{
                font-size: 10px;
            }
        }
        .batch-p{
            margin: -8px 0px 0px;
            position: absolute;
        }
        div.voucherCode{
            color: #1F3A9C;
            display: flex !important;
            align-items: center;
            justify-content: space-between;  
            font-size: 13px;
            .hourglass-icon{
                border-radius: 13.5px;
                border: 1px solid #42526E;
                width: 23px;
                height: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                cursor: pointer;
                background:transparent;
                .pi{
                    font-size: 10px;
                }
                &:hover{
                    background:#42526E;
                    .pi{
                        color: $white;
                    }
                }
                &.icon-reserved{
                    background:#F73859;
                    border: 1px solid #F73859;
                    .pi{
                        color: $white;
                    }
                }
                &.icon-redeemed{
                    background:#42526E;
                    border: 1px solid #42526E;
                    opacity: 0.4;
                    pointer-events: none;
                    .pi{
                        color: $white;
                    }
                }
            }
        }     
    }
    .p-datatable-thead > tr > th{
        padding: 1rem 0rem;
        background: $theme-gray;
        color: $white;
        .p-column-title{
            font-size: 13px;
        }
        .p-sortable-column-icon, .pi-sort-alt{
            color: $white;
            font-size: 12px;
        }
            &.p-highlight{
                .p-column-header-content {
                    background: $theme-gray !important;
                    color: $white !important;
                    .p-sortable-column-icon{
                        color: $white !important;
                    }
                }
                
            }
            &:focus{
                box-shadow: none;
            }
    }
    .status{
        border-radius: 4px;
        padding: 6px 12px;
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        color: $white;
        margin: 0;
        &.redeemed{
            background: #4B9C68;
        }
        &.published{
            background: #E9F7EE;
            color: #0CBB84;
        }
        &.active{
            background: #E9F7EE;
            color: #0CBB84;
        }
        &.pending{
            background: #FFF5BC;
            color: #C88115;
        }
        &.expired{
            background: #F7E9E9;
            color: #BB0C0C;
        }
    }
    .details-btn{
        background: #F4F6FE;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        cursor: pointer;
        .pi{
            font-size: 10px;
        }
        &:hover{
            background: $theme-gray;
            .pi{
                color: $white;
            }
        }
    }
    .batch-column{
        position: relative;
        p{
            margin: 10px 18px 10px 0px;
            font-size: 14px;
            width: 100%;
            max-width: 62%;
            line-height: 2;
        }
        .pi{
            color: #F73859;
            font-size: 18px;
            cursor: pointer;
            &.pi-file{
                position: relative;
                &::after{
                    content: "\e919";
                    position: absolute;
                    font-size: 8px;
                    left: 5px;
                    right: 0px;
                    top: 7px;
                    font-weight: 600;
                }
            }
        }
        &::after{
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background: #e1dede;
            right: 25px;
            top: -1px;
        }
        &::before{
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background: #e1dede;
            right: 23px;
            top: -1px;
        }
    }
    .p-datatable-wrapper{
        border-radius: 10px;
        overflow-x: auto;
        .p-datatable-tbody{
            tr{
                &:last-child{
                    td{
                        border: none;
                    }
                }
            }
        }
    }
}
  
.vouchers-expandable-table{
    margin-top: 0rem;
    .p-datatable-table{
        min-height: auto;
    }
    .p-datatable-thead{
        display: none;
    }
    .p-datatable-tbody{
        td{
            padding: 0.6rem 0rem !important;
        }
    }
    .batchname{
        position: relative;
        overflow: hidden;
        &::after{
            content: "";
            position: absolute;
            width: 1px;
            height: 65px;
            background: #e1dede;
            right: 25px;
            top: -1px;
        }
        &::before{
            content: "";
            position: absolute;
            width: 1px;
            height: 65px;
            background: #e1dede;
            right: 23px;
            top: -1px;
        }
    }
}
.voucher-modal{
    .p-dialog-header{
        padding: 2rem 2rem;
        .p-dialog-title{
            color: $theme-gray;
            font-size: 24px;
            font-weight: 700;
        }
    }
    .p-dialog-content{
        margin-top: -1px;
        .filter-content{
            .p-dropdown{
                height: 45px;
                border: 1px solid #DDD;
                top: 0px;
                .p-dropdown-label{
                    font-size: 15px;
                    padding: 12px 12px;
                    font-weight: normal;
                    color: #858585;
                }
            }
            p{
                margin: 0px;
                font-size: 15px;
                color: $black;
            }
            .p-calendar{
                width: 100%;
                position: relative;
                .p-inputtext{
                    border-radius: 6px;
                    &::placeholder{
                        color: #858585;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
                .p-datepicker-trigger{
                    border: none;
                    background: none;
                    position: absolute;
                    z-index: 9;
                    right: 15px;
                    top: 12px;
                    padding: 0px;
                    border-radius: 0px;
                    width: auto;
                    .p-button-icon{
                        color: #6B6B6B;
                    }
                }
            }
            .p-multiselect .p-multiselect-label.p-placeholder{
                color: #858585;
            }
        }
    }
    &.create-voucher-dialog{
        width: 90%;
        max-width: 1100px;
       .p-dialog-header{
            background: $white;
            .p-dialog-title{
                    text-decoration: underline;
            }
       }
       .p-inputtext{
            &::placeholder{
                color: #858585;
            }
        }
        .p-dialog-content{
            background: $white;
            .search-button{
                min-width: 135px;
                background: $white;
                border-color: $secondary;
                padding: 12px 10px;
                .p-button-label{
                    color: $secondary;
                }
                &:hover{
                    background: $secondary;
                    .p-button-label{
                        color: $white;
                    }
                }
            }
            .vouchersInput{
                width: 80px;
                text-align: center;
                border-radius: 8px;
                border: 1px solid #E4E4E4;
                padding: 0.4rem 0.75rem;
                font-size: 14px;
                &:focus{
                    box-shadow: none;
                }
            }
            .p-checkbox .p-checkbox-box{
                border: 1px solid #3a476a;
                .p-checkbox-icon{
                    color: $secondary;
                }
                &.p-highlight{
                    border: 1px solid $secondary;
                }
            }
            &::-webkit-scrollbar {
                width: 6px;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
                background: #D9D9D9;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background: $theme-gray;
                padding: 2px;
                border-radius: 10px;
            }
            .custom-min-height{
                .p-datatable-table{
                    .p-datatable-tbody{
                        height: 300px;
                        .p-datatable-emptymessage{
                            td{
                                text-align: center;
                                height: 300px;
                            }
                        }
                    }
                }
            }
        }
        .buttons-sections{
            .min-width{
                width: 195px;
                padding: 12px 23px;
            }
            p{
                margin: 0px;
                color: #858585;
            }
            .batch-btn{
                background: $secondary;
                &:hover{
                    background: $theme-gray;
                }
            }
        }
    }
}
.p-dropdown-panel{
    .p-dropdown-items-wrapper{
        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #D9D9D9;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $theme-gray;
            padding: 2px;
            border-radius: 10px;
        }
    }
}
.create-voucher-dialog-mask{
    align-items: center;
}
.bundles-table{
    .p-datatable-thead > tr > th{
        background: $theme-gray;
        color: $white;
        border: 1px solid #7382ad;
        border-width: 1px;
        font-size: 15px;
        padding: 0.8rem 1rem;
    }
    .p-datatable-wrapper{
        border-radius: 10px;
        overflow-x: auto;
        .p-datatable-tbody{
            tr{
                td{
                    border: 1px solid #ededed;
                    border-width: 1px;
                    font-size: 13px;
                    padding: 0.6rem 10px;
                    .pi-info-circle{
                        position: relative;
                        top: 2px;
                        &:hover{
                            color: $secondary;
                        }
                    }
                    &.last-column{
                        text-align: center;
                        .p-inputtext{
                            padding: 0.35rem 0.5rem;
                            width: 75px;
                            text-align: center;
                            border-radius: 8px;
                            border: 1px solid #ced4da;
                        }
                    }
                }
            }
        }
    }
}
.roaming-countries{
    .countries-list{
        line-height: 28px;
        font-size: 15px;
    }
   .p-dialog-header{
        padding: 2rem 3rem 2rem;
   }
   .p-dialog-content{
        padding: 0 3rem 3rem 3rem;
        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #D9D9D9;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $theme-gray;
            padding: 2px;
            border-radius: 10px;
        }
   }
}

@media (min-width:1200px) and (max-width:1300px){
    .vouchers-table .batch-column p{
        max-width: 50%;
    }
}
@media (min-width:1301px) and (max-width:1500px){
    .vouchers-table .batch-column p{
        max-width: 56%;
        margin: 10px 12px 10px 0px;
    }
}

.prepaid-table {
    .p-datatable .p-paginator .p-paginator-current {
        font-weight: 400;
        font-size: 13px;
        color: #161b28;
    }

    .p-column-header-content{
        width: fit-content;
        padding: 10px;
        margin: -10px;
        border-radius: 4px;
    }

    .vouchers-table .p-datatable-thead > tr > th.p-highlight {
        color: $theme-gray !important;
        .p-column-header-content{
            background-color: $primary !important;
        }
        .p-column-title {
            color: $theme-gray !important;
        }
        .p-sortable-column-icon{
            color: $theme-gray !important;
        }
    }

    .p-datatable .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 25px;
        height: 25px;
        font-size: 13px;
    }

    .p-datatable .p-paginator .p-dropdown .p-dropdown-label {
        font-size: 13px;
        padding: 8px 0px 8px 8px;
    }

    .p-datatable .p-paginator .p-dropdown {
        height: auto;
        border: 1px solid #E7EBFF;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .admin-orderlist-section{
        .filter-right{
            .p-inputtext {
                width: 108px;
            }
            .affiliate-dropdown .p-dropdown{
                width: 118px;
            }
        }
        .users-data-table .custom-table .custom-table-header .table-grid .col-ul li span{
            padding: 12px 2px;
        }
        .users-data-table .custom-table .p-dataview .p-dataview-content .grid .custom-table-body .table-grid .col-ul li{
            font-size: 12px;
        }
    }
}

// Responsive CSS

@media (max-width:960px){
    .vouchers-header{
        .mobile-flex{
            flex-direction: column;
            align-items: flex-start !important;
            width: 100%;
            .mobile-screen-width{
                width: 100%;
            }
        }
        h1{
            margin-bottom: 1rem !important;
        }
        .export-button{
            padding: 11px 18px !important;
        }
    }
    .promo-code-section{
        .filter-right.mobile-filter-section{
            .mobile-flex{
                display: block !important;
            }
            .exportBtn{
                background: $black !important;
                margin-top: 1rem;
            }
        }
    }
    .vouchers-table{
        margin-top: 5px;
        .batch-column{
            &::before{
                display: none;
            }
            &::after{
                display: none;
            }
            p{
                margin: 0px;
            }
        }
        .p-datatable-tbody > tr {
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 10px;
            display: block;
            td{
                border: none;
                padding: 5px 0px !important;
                .p-column-title{
                    .pi{
                        display: none;
                    }
                }
                .batch-p{
                    position: inherit;
                    margin: 0px;
                    text-align: right;
                    font-size: 13px;
                    strong{
                        display: block;
                    }
                }
            }
            &.disabled-row{
                td{
                    padding: 5px 10px !important;
                }
            }
            &.p-datatable-row-expansion{
                td{
                    div{
                        width: 100%;
                    }
                    .voucherCode{
                        width: auto !important;
                    }
                    .status{
                        width: auto;
                    }
                }
            }
        }
    }
    .vouchers-expandable-table{
        background: #F5F5F5;
        padding: 5px;
       .p-datatable-tbody > tr > td {
        position: relative;
        padding-left: 50% !important;
            .p-column-title{
                position: absolute;
                left: 0px;
            }
            .voucherCode{
                flex-direction: column;
                span{
                    margin-bottom: 5px;
                }
            }
            &:after {
                content: ':';
                position: absolute;
                left: 40%;
                font-size: 14px;
                font-weight: 600;
            }
       }
    }
    .voucher-modal{
        &.create-voucher-dialog{
            .p-dialog-content{
                .m-flex{
                    flex-direction: column;
                    align-items: flex-start !important;
                }
            }
            .custom-min-height{
                .p-datatable-table {
                    .p-datatable-tbody {
                        height: 100px !important;
                        .p-datatable-emptymessage td{
                            height: 100px !important;
                        }
                    }
                }
            }
            .buttons-sections .min-width{
                width: auto;
            }
        }
        .p-dialog-header{
            padding: 1rem 1rem;
            .p-dialog-title{
                font-size: 20px;
            }
        }
    }
    .buttons-sections{
        .confirm-button.min-width{
            min-width: auto;
        }
    }
    .bundles-table{
        .p-datatable-tbody > tr{
            background: #F5F5F5;
            border-radius: 5px;
            margin-bottom: 10px;
            display: block;
            td{
                text-align: right;
                .p-column-title{
                    text-align: left;
                }
            }
        }
    }
}

@media (max-width:675px){
    .buttons-sections{
        &.mobile-flex{
            flex-direction: column;
        }
    }
}