@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import '../scss/variables';

// **** Prime React CSS **** //
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.scss';

// **** Global CSS **** //

body {
    margin: 0px;
    padding: 0px;
    font-family: $font-base;
}
.p-component{
    font-family: $font-base;
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.error-message {
    color: #f00;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: 5px;
}
.copiedText {
    color: $green;
}
.p-multiselect .p-multiselect-label.p-placeholder {
	color: #000;
    font-weight: 600;
}
.p-inputtext{
    &::placeholder{
        color: #000;
        font-weight: 600; 
    }
}
.esimNonCompatible{
    padding: 1rem 0rem 0rem;
    display: block;
}

// Overriding password toogle mask icon
.p-password {
    .pi-eye{
        &::before {
            content: "\e965" !important;
        }
    }
    .pi-eye-slash{
        &::before {
            content: "\e966" !important;
        }
    }
}

// Link visited color
a.underline, a.underline:active, a.underline:hover {
    color: #33409C;
}

.main{
    .layout-sidebar{
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        width: 15rem;
        background: #f3f6ff;
        display: flex;
        flex-direction: column;
    }
    .layout-content-wrapper{
        margin-left: 15rem;
    }
}

.admin-header-section{
    background: $theme-gray;
    padding: 15px 15px;
    border-radius: 0px;
    border-bottom: 1px dashed #485883;
    img {
        width: 135px;
        margin: 0 auto;
    }
}

.admin-header{
    padding: 10px 10px;
    height: calc(100vh - 80px);
    border-radius: 0px;
    background: linear-gradient(180deg, $theme-gray 0%, #455368 100%);
    .p-menubar{
        background: transparent;
        border: none;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
        height: 100%;
        .p-menubar-root-list{
            display: block;
            width: 100%;
            min-height: 75vh;
            .p-menuitem{
                margin: 5px 0px;
                .p-menuitem-link{
                    padding: 12px 10px;
                    display: block;
                    border-radius: 5px;
                    background: rgba(20, 25, 36, 0.10);
                    .p-menuitem-text{
                        font-weight: 500;
                        font-size: 14px;
                        color: $white;
                    }
                    .p-submenu-icon{
                        color: $white;
                        position: absolute;
                        right: 15px;
                        top: 15px;
                    }
                    .p-menuitem-icon{
                        font-size: 14px;
                        color: $white;
                        &.custom-font{
                            font-size: 12px;
                        }
                    }
                    &:hover{
                        background: $secondary !important;
                        .p-menuitem-text{
                            color: $white !important;
                        }
                        .p-submenu-icon{
                            color: $white !important;
                        }
                        .p-menuitem-icon{
                            color: $white !important;
                        }
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background: $secondary;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        border-radius: 0px;
                        display: none;
                    }
                    &:focus{
                        box-shadow: none;
                    }
                }
                &.active-menu{
                    .p-menuitem-link{
                        background: $secondary;
                    }
                }
            }
        }
        .p-menubar-end{
            margin-left: 0px;
            background: $white;
            border-radius: 5px;
            padding: 8px 10px;
            width: 100%;
        }
        .p-submenu-list{
            background: none;
            position: relative;
            width: 100%;
            .p-menuitem{
                margin: 1px 0px !important;
            }
            .p-menuitem-link{
                background: #5b6581  !important;
            }
            .p-menuitem-icon{
                position: relative;
                top: 1px;
            }
        }
    }
    .p-menubar-end{
        .p-button{
            background: transparent;
            border: none;
            padding: 0px 0px 0px 10px;
            width: 100%;
            .p-button-label{
                color: $theme-gray;
                font-weight: 500;
                font-size: 12px;
                text-align: left;
                text-transform: uppercase;
            }
            .p-button-icon{
                color: $theme-gray;
                font-size: 13px;
                margin-right: 2px;
            }
            &:hover{
                background: transparent;
            }
            &:focus{
                box-shadow: none;
            }
        }
        .user-icon{
            display: inline-block;
            width: 25px;
            min-width: 25px;
            height: 25px;
            background: $theme-gray;
            text-align: center;
            line-height: 24px;
            border-radius: 6px;
            i {
                color: $white;
                font-size: 12px;
            }
        }
        &:hover{
            opacity: 0.8;
            cursor: pointer;
        }
    }
}
.admin-footer{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 30px 30px 0px 0px;
    p{
        font-size: 13px;
        margin: auto;
        padding: 20px 0px;
        text-align: center;
        a {
            color: $secondary;
        }
    }
}

.p-toast{
    opacity: 1;
    max-width: 26rem;
    .p-toast-message{
        &.p-toast-message-success{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #2BA079;
                border-radius: 2px;
            }
        }
        &.p-toast-message-error{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #ff5757;
                border-radius: 2px;
            }
        }
        &.p-toast-message-warn{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #cc8925;
                border-radius: 2px;
            }
        }
        &.p-toast-message-info{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #5774cd;
                border-radius: 2px;
            }
            .p-toast-message-icon{
                background: #5774cd !important;
            }
        }
        .p-toast-message-content{
            padding: 12px;
            padding-left: 25px;
            align-items: center;
            .p-toast-message-icon{
                width: 25px;
                height: 25px;
                border-radius: 30px;
                background:#2BA079;
                color: $white;
                font-size: 14px;
                text-align: center;
                line-height: 26px;
                min-width: 25px;
                &.pi-times{
                    background: #ff5757;
                }
                &.pi-exclamation-triangle{
                    background: #cc8925;
                }
            }
            .p-toast-message-text{
                .p-toast-summary{
                    font-size: 14px;
                    font-weight: 500;
                }
                .p-toast-detail{
                    font-size: 15px;
                    margin: 0.4rem 0 0 0;
                    color: #505050;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .p-toast-icon-close{
            width: auto;
            height: auto;
            color: #505050 !important;
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            .p-toast-icon-close-icon{
                font-size: 13px;
            }
        }
    }
}

@media (min-width:1200px) and (max-width:1700px){
    .admin-header{
        padding: 0px 4px;
    }
    .main{
        .layout-sidebar{
            width: 12rem;
        }
        .layout-content-wrapper {
            margin-left: 12rem;
        }
    }
    .admin-header-section {
        padding: 12px 15px;
    }
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow{
    border-top-color: $theme-gray;
}
.p-tooltip .p-tooltip-text{
    background: $theme-gray;
    font-size: 13px;
}
.p-multiselect-item{
    font-size: 14px;
}

// Responsive CSS

.menu-toggle {
    display: none;
    background: none !important;
    border: none !important;
    .pi{
        color: $white;
        font-size: 20px;
    }
    &:focus{
        box-shadow: none;
    }
}

@media (max-width: 768px) {
    .menu-toggle {
        display: block;
    }
    .admin-header {
        display: none;
        padding: 10px;
        &.menu-show{
            display: block;
        }
        .p-menubar{
            height: 98%;
            .p-menubar-root-list{
                width: 100%;
                min-height: auto;
                position: initial;
                background: transparent;
                box-shadow: none;
            }
        }
    }
    .p-menubar .p-menubar-button{
        display: none;
    }
    .main{
        .layout-sidebar{
            width: 100%;
            position: sticky;
            top: 0px;
            z-index: 999;
        }
        .layout-content-wrapper{
            margin-left: 0rem;
        }
    }
    .heading-sec{
        justify-content: center !important;
        flex-direction: column;
        align-items: flex-start !important;
        .mobile-margin{
            margin-bottom: 0.5rem !important;
        }
    }
}