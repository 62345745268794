@import '../../../assets/scss/variables';

.language-translate{
    margin-top: 2rem;
    background: $white;
    border: 1px solid #DEE2FF;
    border-radius: 5px;
    padding: 20px 20px;
    .custom-bg{
        background: #fff;
    }
    h2{
        font-size: 15px;
        margin: 0px;
    }
    .box{
        background: #f7f7f7;
        padding: 2px 15px;
        font-size: 14px;
        .english-text{
            span{
                background: $theme-gray;
                padding: 6px 8px;
                border-radius: 5px;
                font-size: 12px;
                color: $white;
            }
            p{
                margin: 14px 0px;
                font-size: 14px;
                word-wrap: break-word;
            }
        }
        .icons{
            .action-btn{
                background: $theme-gray;
                width: 2.6rem;
                border: none;
                .p-button-icon{
                    font-size: 14px;
                }
                &:hover{
                    background: $secondary;
                }
            }
        }
    }
    .save-button{
        border: none;
        background: $theme-gray;
        margin-top: 15px;
        padding: 10px 20px;
        .p-button-label{
            font-size: 16px;
            font-weight: 500;
        }
        &:hover{
            background: $secondary;
        }
    }
    .dropdown{
        .p-dropdown{
            min-width: 180px;
            .p-dropdown-label{
                border: none;
                height: 35px;
                padding: 8px 0.75rem;
            }
            .p-dropdown-trigger{
                .p-dropdown-trigger-icon{
                    font-size: 13px;
                }
            }
        }
        .pi-search{
            position: absolute;
            left: 10px;
            top: 10px;
            color: #888;
        }
    }
    .icons{
        .action-buttons{
            width: 30px;
            height: 30px;
            border-radius: 30px;
            margin-right: 8px;
            border: none;
            background: $theme-gray;
            &:hover{
                opacity: 0.8;
            }
            &.close{
                background: $white;
                .p-button-icon{
                    color: $theme-gray;
                }
                &:hover{
                    background: $secondary;
                    .p-button-icon{
                        color: $white;
                    }
                }
            }
            .p-button-icon{
                font-size: 14px;
            }
        }
    }
    .language-search-field{
        min-width: 300px;
        border: 1px solid #ced4da;
        border-radius: 6px;
        height: 37px;
        margin-right: 8px;
        padding-left: 2.2rem;
    }
}