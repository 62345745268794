@import '../../assets/scss/variables';

.maintenance-mode{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto !important;
    min-height: calc(100vh - 200px);
    .p-button{
        padding: 10px 30px;
        line-height: 20px;
        &.Activate {
        background: #1c870d !important;
        border: 1px solid #1c870d !important;
            &:hover{
                background: #1c870d !important;
                border: 1px solid #1c870d !important;
                opacity: 0.8;
            }
        }
        &.Deactivate {
            background: #f53e17 !important;
            border: 1px solid #f53e17 !important;
            &:hover{
                background: #f53e17 !important;
                border: 1px solid #f53e17 !important;
                opacity: 0.8;
            }
        }
        &.greyDefault {
            background: #838181 !important;
            border: 1px solid #838181 !important;
            &:hover{
                background: #838181 !important;
                border: 1px solid #838181 !important;
                opacity: 0.8;
            }
        }
    }
}

// Responsive CSS

@media (max-width:768px){
    .maintenance-mode{
        min-height: calc(100vh - 250px);
        margin: 30px auto 0px !important;
    }
    .maintenance-block{
        min-height: auto;
    }
}