@import '../../../assets/scss/variables';

.general {
    margin-top: 20px;
    .disable-btn{
        pointer-events: none;
        opacity: 0.5;
    }
    .image-preview-common {
        margin-top: 10px;
        text-align: left;
        max-width: 100%;
        max-height: 10px;
    }
    .colorGrid{
        display: grid;
        grid-template-columns: max-content max-content max-content;
        grid-gap: 28px;
    }
    .colorPickerPanel {
        background: #f7f7f7ab;
        padding: 12px 12px;
        border-radius: 10px;
        .colorPickersColumn {
            display: grid;
            grid-template-columns: 180px max-content max-content max-content;
            grid-gap: 4px; 
            margin-bottom: 12px;
            align-items: center;
        }
        .colorPickers {
            .colorLabels {
                margin-bottom: 0.5rem !important;
                font-weight: 700 !important;
            }
            .p-button {
                padding: 0.3rem 0.8rem !important;
            }
            .p-colorpicker {
                .p-inputtext {
                    width: 40px;
                    height: 40px;
                }
            }
            h4{
                font-size: 14px;
                font-weight: 500;
                margin: 0px 0px 10px;
            }
        }
        .border-light{
            .p-button{
                border-color: rgb(199 199 199) !important;
            }
        }
    }
    .file-upload-section{
        padding: 0px 0px 0px 0px;
    }
    .logo_icon{
        img{
            width: auto;
            height: 41px;
            margin: 0px 15px;
            border: 1px solid #DEE2FF;
            padding: 4px;
            border-radius: 5px;
            position: relative;
            top: 2px;
            &:hover{
                transform: scale(1.2);
                cursor: pointer;
                transition: all 0.2s ease-in-out;
            }
        }
    }
    .fav_icon{
        img{
            width: 40px;
            height: 40px;
            margin: 0px 15px;
            border: 1px solid #DEE2FF;
            padding: 4px;
            border-radius: 5px;
            position: relative;
            top: 2px;
            &:hover{
                transform: scale(1.2);
                cursor: pointer;
                transition: all 0.2s ease-in-out;
            }
        }
    }
    .font-family-section{
        .dropdown-button{
            background: #ffffff;
            border: 1px solid #DEE2FF;
            height: 45px;
            border-radius: 5px;
            &:hover{
                background: #ffffff;
            }
            &:focus{
                background: #ffffff;
            }
        }
        .font-list{
            .font-list-item{
                button{
                    background: #fff;
                    &:hover{
                        background: #F3F6FF; 
                    }
                }
            }
        }
        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
            background: $secondary;
            margin-bottom: -10px;
            margin-top: 10px;
        }
        .p-inputswitch-slider{
            margin-bottom: -10px;
            margin-top: 10px;
        }
        .apply-font-fontBase, .apply-font-fontSecondary{
            font-size: 14px;
            color: #666;
        }
        #font-picker-fontBase{
            box-shadow: none;
            width: 100%;
            max-width: 375px;
        }
        #font-picker-fontSecondary{
            box-shadow: none;
            width: 100%;
            max-width: 375px;
        }
    }
    .image-preview{
        margin-left: 5px;
    }
    .image-preview img {
        max-width: 100%;
        max-height: 50px;
        border-radius: 5px;
        background: #dfbbc2;
        padding: 4px;
    }
}