@import '../../../assets/scss/variables';

.contact {
    margin-top: 20px;
    .p-picklist{
        .p-picklist-buttons{
            .p-button{
                background: $grey-dark;
                border: none;
                &:hover{
                    background: $secondary;
                }
            }
        }
        .p-picklist-list-wrapper{
            .p-picklist-list {
                .product-item{
                    .product-list-detail{
                        p{
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
    .panel-card{
        background: #f7f7f7;
        padding: 15px;
        border-radius: 10px;
    }
}