$font-base: 'Lato', sans-serif;
// Colors
$primary: #c1deea;
$secondary: #f73759;
$secondary-light: #f96982;
$green: #026437;
$blue-light: #E7EBFF;
$black: #161b28;
$white: #ffffff;
$theme-gray: #3a476a;
$grey-dark: #283149;
$error-red:#DB3232;
$green-light: #1f973a;
$blue:#3b82f6;
