@import '../../assets/scss/variables';

.cms-section {
    background: #ffffff;
    box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    justify-content: center;
    width: 100%;
    margin: 30px auto;
    padding: 25px;

    h2 {
        margin: 0px 0px 25px;
        font-size: 20px;
    }

    .error-msg {
        color: #f00;
        font-size: 13px;
        font-weight: 400;
        margin-top: 2px;
        text-align: left;
        position: relative;

    }

    .imageFormat {
        display: none;
        font-size: 12px;
        font-style: italic;
        color: #8d8d8d;
        font-weight: 300;
    }

    .p-inputtext {
        width: 100%;
        height: 45px;
        background: $white;
        border: 1px solid #DEE2FF;
        border-radius: 5px;
        font-family: $font-base;
        font-size: 14px;
        &.contact-description{
            min-height: 58px;
        }
    }

    label {
        font-size: 15px;
        font-weight: 400;
        color: $black;
        display: block;
    }

    .cms-buttons {
        background: $theme-gray;
        border: 1px solid $theme-gray;
        border-radius: 5px;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        padding: 8px 20px;
        cursor: pointer;
        margin-right: 10px;

        &:hover {
            background: $secondary;
            color: $white;
            border: 1px solid $secondary;
        }

        &.previous-btn {
            background: $primary;
            color: $theme-gray;

            &:hover {
                background: $theme-gray;
                color: $white;
                border: 1px solid $theme-gray;
            }
        }

        &:disabled,
        [disabled] {
            opacity: 0.4;
            pointer-events: none;
        }
    }
}

.cmsLabels {
    font-weight:700 !important;
}

.image-preview {
    text-align: left;
}
.image-preview{
    img {
        max-width: 100%;
        max-height: 50px;
        border-radius: 5px;
        &.logo_icon{
            margin-top: 5px;
        }
    }
}
.features-section-image-preview{
    border-radius: 5px;
    background-color: #f36881;
    .image-preview{
        text-align: center;
        .logo_icon{
            margin-top: 0px;
        }
    }
}

.saveBtn {
    background: $secondary;
    border: none;
    border-radius: 5px;
    margin-top: 35px;
    padding: 10px 45px;
    span {
        font-weight: 500;
        font-size: 16px;
    }
    &:hover {
        background: $grey-dark !important;
    }
}

.tabs-section{
    .p-tabmenu {
        display: inline-flex;
        background: #f3f6ff;
        padding: 7px 7px 9px;
        border-radius: 4px;
        border: 1px solid#eee;
        .p-tabmenu-nav{
            background: transparent;
            border: none;
            .p-tabmenuitem{
                .p-menuitem-link{
                    padding: 8px 16px 8px 12px;
                    font-size: 13px;
                    font-weight: 500;
                    color: $black;
                    border: none;
                    border-radius: 4px;
                    background: none;
                    border: 1px solid transparent;
                    &:hover{
                        background: $white;
                        border: 1px solid #CFD8E3;
                        box-shadow: 2px 4px 18px 0px rgba(111, 110, 151, 0.15);
                    }
                    .pi{
                        font-size: 13px;
                    }
                }
                &.p-highlight{
                    .p-menuitem-link{
                        background: $white;
                        border: 1px solid #CFD8E3;
                        box-shadow: 2px 4px 18px 0px rgba(111, 110, 151, 0.15);
                    }
                }
            }
            .p-tabmenu-ink-bar{
                display: none;
            }
        }
    }
}
.admin-userlist-section{
    position: relative;
    .progress-spinner{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        .progressSpinner{
            width: 60px;
            height: 60px;
        }
    }
}

// Responsive CSS

@media (min-width:1200px) and (max-width:1440px){
    .cms-section .imageFormat{
        display: block;
        margin-top: 2px;
    }
}

@media (max-width:768px){
    .tabs-section .p-tabmenu{
        display: block;
    }
    .general .colorGrid{
        grid-template-columns: auto;
    }
}